<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" class="mt-5 mb-5">
        <b-col cols="auto">
          <h1
            class="mb-0 text-primary"
            style="font-size: 22px; font-weight: 500"
          >
            Prescriptions
          </h1>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      :lg="Object.keys(rowSelected).length > 0 ? '9' : '12'"
      class="tw-pl-0 tw-pr-0"
    >
      <dashboard-table
        :table-header="fields"
        :table-item="items"
        :item-per-page="8"
        scroll-height="calc(100vh - 250px)"
        scrollable
        is-selectable
        has-search
        @scroll="prescriptionScroll"
        @selectedPrescription="selectedRow"
        @prescribed="refetchPrescriptions"
        @searchPrescription="searchPrescription"
        v-if="!isBusy"
        class="prescription-table"
        ref="tableTest"
      />

      <b-skeleton-table
        animation="fade"
        v-else
        :rows="6"
        :columns="5"
      ></b-skeleton-table>
    </b-col>
    <b-col
      cols="12"
      lg="3"
      v-if="Object.keys(rowSelected).length > 0"
      class="p-0"
    >
      <b-card
        class="
          patient-info
          mb-0
          tw-fixed tw-top-[83px] tw-left-0 tw-right-0 tw-z-[20]
          xl:tw-relative xl:tw-top-0
        "
      >
        <template v-slot:header>
          <b-row align-v="center">
            <b-col>
              <h3 class="mb-0 bold">
                <img
                  style="cursor: pointer"
                  @click="hidePatientInfo({})"
                  width="25px"
                  src="@/assets/images/back_arrow.svg"
                />
                &nbsp;&nbsp; Patient Prescription Info
              </h3>
            </b-col>
          </b-row>
        </template>
        <b-row v-if="isLoading">
          <b-col cols="12" class="mb-5">
            <b-row align-v="center">
              <b-col cols="auto">
                <b-skeleton
                  animation="fade"
                  type="avatar"
                  size="85px"
                ></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton animation="fade"></b-skeleton>
                <b-skeleton
                  animation="fade"
                  type="button"
                  width="100%"
                ></b-skeleton>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row align-v="start">
              <b-col cols="12" v-for="i in 4" :key="i" class="mb-3">
                <b-skeleton
                  animation="fade"
                  height="10px"
                  width="50%"
                  class="mb-2"
                ></b-skeleton>
                <b-skeleton animation="fade"></b-skeleton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" class="mb-3">
            <b-row align-v="center">
              <b-col cols="auto">
                <b-avatar size="85px" :src="patientInfo.profileUrl"></b-avatar>
              </b-col>
              <b-col>
                <h3 class="bold">{{ rowSelected.patientname }}</h3>
                <b-button
                  pill
                  size="sm"
                  variant="outline-dark"
                  :href="`mailto:${patientInfo.email}`"
                  block
                >
                  <b-icon icon="envelope" aria-hidden="true"></b-icon
                  >&nbsp;&nbsp;&nbsp;Email
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mb-3">
            <b-row>
              <b-col cols="12" md="6">
                <p class="text-muted mb-2">
                  <small>Condition</small>
                </p>
                <h4 class="text-black">{{ rowSelected.symptoms }}</h4>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Other Medications </small>
            </p>
            <div v-if="patientInfo.othermedication">
              <h4
                class="text-black"
                v-for="(medication, i) in patientInfo.othermedication"
                :key="i"
              >
                {{ medication }}
              </h4>
            </div>

            <h4 v-else class="text-black">No records!</h4>
          </b-col>
          <b-col cols="12" class="mb-3">
            <h3 class="mb-0 text-black">Treatment progress</h3>
          </b-col>
          <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Ordered</small>
            </p>
            <h4 class="text-black">
              {{ moment(rowSelected.timestampaccepted).format("MM-DD-YYYY") }}
            </h4>
          </b-col>
          <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Prescription</small>
            </p>
            <h4 class="text-black">{{ rowSelected.medicinename }}</h4>
          </b-col>
          <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Dosage</small>
            </p>
            <h4 class="text-black">
              {{ `${rowSelected.dosage} ${rowSelected.frequency}` }}
            </h4>
          </b-col>
          <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Total quantity</small>
            </p>
            <h4 class="text-black">{{ rowSelected.unit }}</h4>
          </b-col>
        </b-row>
        <template v-slot:footer v-if="patientInfo">
          <b-row v-if="isLoading">
            <b-col cols="12" class="mb-3">
              <b-skeleton
                animation="fade"
                type="button"
                width="100%"
              ></b-skeleton>
            </b-col>
            <b-col cols="12">
              <b-skeleton
                animation="fade"
                type="button"
                width="100%"
              ></b-skeleton>
            </b-col>
          </b-row>
          <template v-else>
            <b-button
              pill
              variant="secondary"
              :to="`/prescriptions/order/${patientInfo.id}`"
              block
              >Write Prescription</b-button
            >
            <b-button
              pill
              variant="outline-secondary"
              :to="`/patients/profile/${patientInfo.id}`"
              block
              >View Patient Profile</b-button
            >
          </template>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import { PrescriptionService } from "@/services/prescription.service.js";

import dashboardTable from "@/components/partials/DashboardTable";
export default {
  name: "prescriptions",
  components: {
    dashboardTable,
  },
  data() {
    return {
      nextPage: 1,
      isBusy: true,
      isLoading: true,
      rowSelected: {},
      tempArrPrescriptions: [],
      fields: [
        { key: "id", label: "Patient ID", sortable: true },
        { key: "name", label: "Patient Name", sortable: true },
        { key: "condition", sortable: false },
        { key: "prescription", label: "Prescription (Rx)", sortable: false },
        { key: "prescriptionstatus", label: "Status", sortable: false },
        { key: "actionOrder", label: "Action", sortable: true },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      prescriptions: "prescription/getDoctorPrescription",
      patientInfo: "patients/getPatientInfo",
    }),
  },
  methods: {
    hidePatientInfo(data) {
      this.rowSelected = data;
      this.$refs.tableTest.$refs.selectableTable.clearSelected();
    },
    async selectedRow(data) {
      this.isLoading = true;
      this.rowSelected = data;
      // console.log('pumasok', data)
      if (Object.keys(data).length > 0) {
        localStorage.setItem("prescriptionOrderId", data.id);
        await this.$store.dispatch("patients/getPatientInfo", {
          patientId: data.patientid,
        });
        this.isLoading = false;
      }
    },

    async searchPrescription(filter) {
      if (filter != "") {
        this.items = [];
        let tempArray = [];
        let prescriptions = await this.$store.dispatch(
          "prescription/searchPrescription",
          filter
        );
        for (let i = 0; i < prescriptions.length; i++) {
          let prescription = prescriptions[i];
          let prescriptionObj = {
            id: prescription.patientno,
            prescriptionid: prescription.id,
            name: prescription.patientname,
            condition: prescription.symptoms,
            prescription: prescription.medicinename,
            prescriptionstatus: prescription.prescriptionstatus,
            date: this.moment.unix(prescription.startdate).format("MM-DD-YYYY"),
            data: prescription,
            // actionOrder: ["Update", "Prescribe", "Delete"],
          };
          if (prescriptionObj.prescriptionstatus === "DRAFTED") {
            prescriptionObj.actionOrder = ["Update", "Prescribe", "Delete"];
          } else if (
            prescriptionObj.prescriptionstatus === "FOR CONFIRMATION"
          ) {
            prescriptionObj.actionOrder = [
              "Update",
              "Send Confirmation",
              "Delete",
            ];
          } else if (
            prescriptionObj.prescriptionstatus === "PENDING CONFIRMATION"
          ) {
            prescriptionObj.actionOrder = ["Delete"];
          } else if (prescriptionObj.prescriptionstatus === "ACCEPTED") {
            prescriptionObj.actionOrder = ["Prescribe"];
          } else if (prescriptionObj.prescriptionstatus == "PRESCRIBED") {
            prescriptionObj.actionOrder = ["Cancel"];
          } else if (prescriptionObj.prescriptionstatus == "REFILL") {
            prescriptionObj.actionOrder = ["Refill", "Cancel"];
          } else if (prescriptionObj.prescriptionstatus == "COMPLETED") {
            prescriptionObj.actionOrder = ["Refill"];
          } else if (prescriptionObj.prescriptionstatus == "FOLLOW-UP") {
            prescriptionObj.actionOrder = ["Continue", "Stop"];
          } else if (prescriptionObj.prescriptionstatus === "DECLINED") {
            prescriptionObj.actionOrder = ["Delete"];
          } else {
            prescriptionObj.actionOrder = "";
          }

          tempArray.push(prescriptionObj);
        }
        Promise.all(tempArray).then((res) => {
          this.items = res;
        });
      } else {
        return await this.getPrescriptions(null);
      }
    },
    async refetchPrescriptions() {
      this.tempArrPrescriptions = [];
      await this.getPrescriptions(this.nextPage);
    },
    getPrescriptions(nextpage) {
      this.$store
        .dispatch("prescription/getDoctorPrescription", nextpage)
        .then((res) => {
          this.tempArrPrescriptions = [];
          for (let i = 0; i < this.prescriptions.items.length; i++) {
            let prescription = this.prescriptions.items[i];
            let prescriptionObj = {
              id: prescription.patientno,
              prescriptionid: prescription.id,
              name: prescription.patientname,
              condition: prescription.symptoms,
              prescription: prescription.medicinename,
              prescriptionstatus: prescription.prescriptionstatus,
              date: this.moment
                .unix(prescription.startdate)
                .format("MM-DD-YYYY"),
              data: prescription,
              // actionOrder: ["Update", "Prescribe", "Delete"],
            };
            if (prescriptionObj.prescriptionstatus === "DRAFTED") {
              prescriptionObj.actionOrder = ["Update", "Prescribe", "Delete"];
            } else if (
              prescriptionObj.prescriptionstatus === "FOR CONFIRMATION"
            ) {
              prescriptionObj.actionOrder = [
                "Update",
                "Send Confirmation",
                "Delete",
              ];
            } else if (
              prescriptionObj.prescriptionstatus === "PENDING CONFIRMATION"
            ) {
              prescriptionObj.actionOrder = ["Delete"];
            } else if (prescriptionObj.prescriptionstatus === "ACCEPTED") {
              prescriptionObj.actionOrder = ["Prescribe"];
            } else if (prescriptionObj.prescriptionstatus == "PRESCRIBED") {
              prescriptionObj.actionOrder = ["Cancel"];
            } else if (prescriptionObj.prescriptionstatus == "REFILL") {
              prescriptionObj.actionOrder = ["Refill", "Cancel"];
            } else if (prescriptionObj.prescriptionstatus == "COMPLETED") {
              prescriptionObj.actionOrder = ["Refill"];
            } else if (prescriptionObj.prescriptionstatus == "FOLLOW-UP") {
              prescriptionObj.actionOrder = ["Continue", "Stop"];
            } else if (prescriptionObj.prescriptionstatus === "DECLINED") {
              prescriptionObj.actionOrder = ["Delete"];
            } else {
              prescriptionObj.actionOrder = "";
            }

            this.tempArrPrescriptions.push(prescriptionObj);
          }
          this.nextPage = res.nextpage;
          this.items = this.tempArrPrescriptions;
          this.isBusy = false;
        });
    },
    async prescriptionScroll(e) {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
        if (this.nextPage) {
          const { data: prescriptions } =
            await PrescriptionService.getDoctorPrescription(this.nextPage);

          for (let i = 0; i < prescriptions.items.length; i++) {
            let prescription = prescriptions.items[i];
            let prescriptionObj = {
              id: prescription.patientno,
              prescriptionid: prescription.id,
              name: prescription.patientname,
              condition: prescription.symptoms,
              prescription: prescription.medicinename,
              prescriptionstatus: prescription.prescriptionstatus,
              date: this.moment
                .unix(prescription.startdate)
                .format("MM-DD-YYYY"),
              data: prescription,
              // actionOrder: ["Update", "Prescribe", "Delete"],
            };
            if (prescriptionObj.prescriptionstatus === "DRAFTED") {
              prescriptionObj.actionOrder = ["Update", "Prescribe", "Delete"];
            } else if (
              prescriptionObj.prescriptionstatus === "FOR CONFIRMATION"
            ) {
              prescriptionObj.actionOrder = [
                "Update",
                "Send Confirmation",
                "Delete",
              ];
            } else if (
              prescriptionObj.prescriptionstatus === "PENDING CONFIRMATION"
            ) {
              prescriptionObj.actionOrder = ["Delete"];
            } else if (prescriptionObj.prescriptionstatus === "ACCEPTED") {
              prescriptionObj.actionOrder = ["Prescribe"];
            } else if (prescriptionObj.prescriptionstatus == "PRESCRIBED") {
              prescriptionObj.actionOrder = ["Cancel"];
            } else if (prescriptionObj.prescriptionstatus == "REFILL") {
              prescriptionObj.actionOrder = ["Refill", "Cancel"];
            } else if (prescriptionObj.prescriptionstatus == "COMPLETED") {
              prescriptionObj.actionOrder = ["Refill"];
            } else if (prescriptionObj.prescriptionstatus == "FOLLOW-UP") {
              prescriptionObj.actionOrder = ["Continue", "Stop"];
            } else if (prescriptionObj.prescriptionstatus === "DECLINED") {
              prescriptionObj.actionOrder = ["Delete"];
            } else {
              prescriptionObj.actionOrder = "";
            }

            this.tempArrPrescriptions.push(prescriptionObj);
          }

          if (prescriptions.nextpage) {
            this.nextPage = prescriptions.nextpage;
          } else {
            this.nextPage = null;
          }
        }
      }
    },
  },
  async mounted() {
    window.analytics.page();
    this.nextPage = null;
    await this.getPrescriptions(this.nextPage);
  },
};
</script>

<style lang="scss" scoped>
.text-muted {
  color: #4f4f4f !important;
}

.content {
  background-color: $white;
  padding: 20px;
}

.patient-info {
  background-color: $quaternary;
  border-color: $quaternary;

  .card-header {
    border-bottom: 0;
  }

  .card-body {
    height: calc(100vh - 370px);
    overflow: auto;

    @include media-breakpoint-down(md) {
      height: calc(100vh - 265px) !important;
    }
  }
}
</style>
