var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            { staticClass: "mt-5 mb-5", attrs: { "align-v": "center" } },
            [
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c(
                  "h1",
                  {
                    staticClass: "mb-0 text-primary",
                    staticStyle: { "font-size": "22px", "font-weight": "500" },
                  },
                  [_vm._v(" Prescriptions ")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass: "tw-pl-0 tw-pr-0",
          attrs: {
            cols: "12",
            lg: Object.keys(_vm.rowSelected).length > 0 ? "9" : "12",
          },
        },
        [
          !_vm.isBusy
            ? _c("dashboard-table", {
                ref: "tableTest",
                staticClass: "prescription-table",
                attrs: {
                  "table-header": _vm.fields,
                  "table-item": _vm.items,
                  "item-per-page": 8,
                  "scroll-height": "calc(100vh - 250px)",
                  scrollable: "",
                  "is-selectable": "",
                  "has-search": "",
                },
                on: {
                  scroll: _vm.prescriptionScroll,
                  selectedPrescription: _vm.selectedRow,
                  prescribed: _vm.refetchPrescriptions,
                  searchPrescription: _vm.searchPrescription,
                },
              })
            : _c("b-skeleton-table", {
                attrs: { animation: "fade", rows: 6, columns: 5 },
              }),
        ],
        1
      ),
      Object.keys(_vm.rowSelected).length > 0
        ? _c(
            "b-col",
            { staticClass: "p-0", attrs: { cols: "12", lg: "3" } },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "patient-info mb-0 tw-fixed tw-top-[83px] tw-left-0 tw-right-0 tw-z-[20] xl:tw-relative xl:tw-top-0",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c("b-col", [
                                  _c("h3", { staticClass: "mb-0 bold" }, [
                                    _c("img", {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        width: "25px",
                                        src: require("@/assets/images/back_arrow.svg"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.hidePatientInfo({})
                                        },
                                      },
                                    }),
                                    _vm._v("    Patient Prescription Info "),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      _vm.patientInfo
                        ? {
                            key: "footer",
                            fn: function () {
                              return [
                                _vm.isLoading
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "mb-3",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("b-skeleton", {
                                              attrs: {
                                                animation: "fade",
                                                type: "button",
                                                width: "100%",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("b-skeleton", {
                                              attrs: {
                                                animation: "fade",
                                                type: "button",
                                                width: "100%",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "secondary",
                                            to: `/prescriptions/order/${_vm.patientInfo.id}`,
                                            block: "",
                                          },
                                        },
                                        [_vm._v("Write Prescription")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "outline-secondary",
                                            to: `/patients/profile/${_vm.patientInfo.id}`,
                                            block: "",
                                          },
                                        },
                                        [_vm._v("View Patient Profile")]
                                      ),
                                    ],
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm.isLoading
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mb-5", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          animation: "fade",
                                          type: "avatar",
                                          size: "85px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c("b-skeleton", {
                                        attrs: { animation: "fade" },
                                      }),
                                      _c("b-skeleton", {
                                        attrs: {
                                          animation: "fade",
                                          type: "button",
                                          width: "100%",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "start" } },
                                _vm._l(4, function (i) {
                                  return _c(
                                    "b-col",
                                    {
                                      key: i,
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("b-skeleton", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          animation: "fade",
                                          height: "10px",
                                          width: "50%",
                                        },
                                      }),
                                      _c("b-skeleton", {
                                        attrs: { animation: "fade" },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("b-avatar", {
                                        attrs: {
                                          size: "85px",
                                          src: _vm.patientInfo.profileUrl,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c("h3", { staticClass: "bold" }, [
                                        _vm._v(
                                          _vm._s(_vm.rowSelected.patientname)
                                        ),
                                      ]),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            size: "sm",
                                            variant: "outline-dark",
                                            href: `mailto:${_vm.patientInfo.email}`,
                                            block: "",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "envelope",
                                              "aria-hidden": "true",
                                            },
                                          }),
                                          _vm._v("   Email "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-muted mb-2" },
                                        [_c("small", [_vm._v("Condition")])]
                                      ),
                                      _c("h4", { staticClass: "text-black" }, [
                                        _vm._v(
                                          _vm._s(_vm.rowSelected.symptoms)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "text-muted mb-2" }, [
                                _c("small", [_vm._v("Other Medications ")]),
                              ]),
                              _vm.patientInfo.othermedication
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.patientInfo.othermedication,
                                      function (medication, i) {
                                        return _c(
                                          "h4",
                                          { key: i, staticClass: "text-black" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(medication) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c("h4", { staticClass: "text-black" }, [
                                    _vm._v("No records!"),
                                  ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c("h3", { staticClass: "mb-0 text-black" }, [
                                _vm._v("Treatment progress"),
                              ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "text-muted mb-2" }, [
                                _c("small", [_vm._v("Ordered")]),
                              ]),
                              _c("h4", { staticClass: "text-black" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .moment(
                                          _vm.rowSelected.timestampaccepted
                                        )
                                        .format("MM-DD-YYYY")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "text-muted mb-2" }, [
                                _c("small", [_vm._v("Prescription")]),
                              ]),
                              _c("h4", { staticClass: "text-black" }, [
                                _vm._v(_vm._s(_vm.rowSelected.medicinename)),
                              ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "text-muted mb-2" }, [
                                _c("small", [_vm._v("Dosage")]),
                              ]),
                              _c("h4", { staticClass: "text-black" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${_vm.rowSelected.dosage} ${_vm.rowSelected.frequency}`
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-3", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "text-muted mb-2" }, [
                                _c("small", [_vm._v("Total quantity")]),
                              ]),
                              _c("h4", { staticClass: "text-black" }, [
                                _vm._v(_vm._s(_vm.rowSelected.unit)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }